<template lang="pug">
AppLayout
  template(v-slot:header)
    v-spacer
    v-toolbar-title.cash-register-name.mx-2.align-center Контакты
    v-spacer
    v-icon mdi-calendar
  Contacts
</template>
<script>
import AppLayout from 'T@/AppLayout.vue'
import Contacts from './contacts/Contacts.vue'

export default {
  components: {
    AppLayout,
    Contacts,
    Basket: () => import('T@/components/cart/Basket.vue'),
  },
}
</script>
<style lang="stylus"></style>
